import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/assets/dsfr_plus_icons.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.ico");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["DsfrProvider"] */ "/app/node_modules/@codegouvfr/react-dsfr/next-appdir/DsfrProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-notion-x/src/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/ui/app/client_only_providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StartDsfr"] */ "/app/ui/dsfr-setup/start-dsfr.tsx");
;
import(/* webpackMode: "eager" */ "/app/ui/public/styles/application.css");
;
import(/* webpackMode: "eager" */ "/app/ui/public/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/app/ui/public/styles/notion.css");
;
import(/* webpackMode: "eager" */ "/app/ui/styles/search.css");
